export default {
  'address-check': {
    header: 'Geschäftspartner',
    loading: {
      identityProviderConfig: 'Loading identity provider config...',
      login: 'Logging in to identity provider...',
      addressCheck: 'Loading address check...',
    },
  },
  'address-check-form': {
    content: {
      heading: {
        create: 'Adresscheck erstellen',
      },
      title: {
        label: 'Adresscheckbezeichnung',
        error: {
          required:
            'Das ist ein Pflichtfeld. Bitte geben Sie die Adresscheckbezeichnung an.',
        },
      },
      createdAt: {
        label: 'Erstellt am',
        error: {
          required:
            'Das ist ein Pflichtfeld. Bitte geben Sie ein Erstelldatum an.',
        },
      },
      createdBy: {
        label: 'Ersteller',
      },
      startAt: {
        label: 'Start am',
        error: {
          required:
            'Das ist ein Pflichtfeld. Bitte geben Sie ein Startdatum an.',
          past: 'Das Startdatum darf nicht in der Vergangenheit liegen.',
        },
      },
      lottery: {
        label: 'Gewinnspiel aktivieren',
      },
      schoolList: {
        label: 'Selektionsergebnis importieren',
        fileType: {
          label: '.csv',
        },
        error: {
          invalid: 'Geben Sie eine gültige CSV Datei an.',
          minLength:
            'Ein Adresscheck muss mindestens an eine Institution gerichtet sein.',
          required:
            'Wählen Sie eine CSV Datei mit mindestens einer Adresse aus.',
        },
      },
      openBi: {
        label: 'Bi-Selektion Öffnen',
      },
      schoolMailReminder: {
        label:
          'Erinnerungsmail nach 2 Wochen wenn der Adresscheck noch nicht abgeschlossen wurde',
      },
      preview: {
        button: {
          label: 'Vorschau anzeigen',
        },
        warning: 'Achtung! Diese Funktion ist in der Vorschau nicht verfügbar!',
      },
      description: {
        label: 'Beschreibung',
      },
      configuration: {
        label: 'Konfiguration',
        placeholders: {
          accessCode: {
            placeholder: {
              label: '{{Berechtigungscode}}',
            },
            tooltip: {
              label: 'Berechtigungscode einfügen',
            },
          },
        },
        schoolMail: {
          label: 'E-Mail an die Schule',
        },
        schoolMailSubject: {
          label: 'E-Mail Betreff',
          tooltip:
            'Betreff für die E-Mail mit der die Institution aufgefordert wird, Adresscheck zu machen.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Betreff an.',
          },
        },
        schoolMailPreviewText: {
          label: 'E-Mail Vorschautext',
          tooltip: 'Vorschauzeile für die MailWorks E-Mail-Vorlage.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Vorschautext an.',
          },
        },
        schoolMailContent: {
          label: 'E-Mail Inhalt',
          tooltip:
            'Inhalt für die E-Mail mit der die Institution aufgefordert wird, Adresscheck zu machen.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Inhalt an.',
          },
        },
        schoolMailPostScript: {
          label: 'E-Mail Postscript',
          tooltip:
            'Inhalt für den PS (Postskriptum) Abschnitt der Aufforderungsmail.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Postscript an.',
          },
        },
        teacherMail: {
          label: 'Interner Schulverteiler',
        },
        teacherMailSubject: {
          label: 'E-Mail Betreff',
          tooltip:
            'Betreff für die E-Mail welche von der Schuldirektior:in an den internen Verteiler (eigene Mitarbeiter) versendet wird.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Betreff an.',
          },
        },
        teacherMailContent: {
          label: 'E-Mail Inhalt',
          tooltip:
            'Betreff für die E-Mail welche von der Schuldirektior:in an den internen Verteiler (eigene Mitarbeiter) versendet wird.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen E-Mail Inhalt an.',
          },
        },
        welcomePage: {
          label: 'Einleitungsseite',
        },
        welcomePageBody: {
          label: 'Inhalt',
          tooltip: 'Inhalt der ersten Seite des Adresscheck Formulars',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Inhalt an.',
          },
        },
        welcomePageMotivator: {
          label: 'Motivator',
          tooltip:
            'Textuelle Beschreibung des Motivators z.B. Beschreibung des Gewinnspiels.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Motivator an.',
          },
        },
        schoolData: {
          label: 'Korrektur der Schuldaten',
        },
        schoolDataPageBody: {
          label: 'Inhalt',
          tooltip:
            'In diesem Feld kann der textuelle Inhalt der zweiten Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Inhalt an.',
          },
        },
        schoolDataPageMotivatorTitle: {
          label: 'Motivator Titel',
          tooltip: 'Möchten Sie am Gewinnspiel und Verlosung teilnehmen?',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Motivator Titel an.',
          },
        },
        schoolDataPageMotivatorLabel: {
          label: 'Labelbeschriftung für CheckBox',
          tooltip: 'Beschriftung der Checkbox für das Gewinnspiel',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie eine Labelbeschriftung an.',
          },
        },
        schoolDataPageMotivatorNotice: {
          label: 'Anmerkung zum Gewinnspiel',
          tooltip: 'Anmerkung zum Gewinnspiel',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie eine Anmerkung an.',
            pattern:
              'Ersetzen Sie den Platzhalter (XX.XX.XXXX) durch ein zukünftiges Datum.',
          },
        },
        teacherListPage: {
          label: 'Lehrer:innen Liste',
        },
        teacherListPageBody: {
          label: 'Inhalt',
          tooltip:
            'In diesem Feld kann der textuelle Inhalt der dritten Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Inhalt an.',
          },
        },
        teacherListPageGdprNotice: {
          label: 'DSGVO Hinweis',
          tooltip:
            'In diesem Feld kann DSGVO Hinweis für die dritte Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen DSGVO Hinweis an.',
          },
        },
        teacherInvitationPage: {
          label: 'Lehrer:innen Einladung',
        },
        teacherInvitationPageBody: {
          label: 'Inhalt',
          tooltip:
            'In diesem Feld kann der textuelle Inhalt der vierten Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Inhalt an.',
          },
        },
        teacherInvitationPageGdprNotice: {
          label: 'DSGVO Hinweis',
          tooltip:
            ' In diesem Feld kann DSGVO Hinweis für die dritte Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen DSGVO Hinweis an.',
          },
        },
        completionPage: {
          label: 'Abschlussseite',
        },
        completionPageBody: {
          label: 'Inhalt',
          tooltip:
            'In diesem Feld kann der textuelle Inhalt der fünften und der letzten Seite des Adresscheck Formulars definiert werden.',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie einen Inhalt an.',
          },
        },
      },
      create: {
        label: 'Adresscheck erstellen',
      },
    },
  },
  'address-check-search': {
    loading: {
      identityProviderConfig: 'Loading identity provider config...',
      login: 'Logging in to identity provider...',
      addressCheck: 'Loading address check...',
    },
    status: {
      STARTED: 'Gestartet',
      COMPLETED: 'Abgeschlossen',
      SENT: 'Gesendet',
      SENT_WITH_ERRORS: 'Fehlerhaft abgesendet',
      ERROR: 'Fehler',
      NEW: 'Neu',
      RETRY: 'Retry',
    },
    content: {
      heading: {
        search: 'Adresscheck suchen',
      },
      table: {
        title: {
          label: 'Titel',
        },
        description: {
          label: 'Beschreibung',
        },
        institutionCount: {
          label: 'Anzahl der Schulen',
          tooltip: {
            total: 'Gesamt',
            open: 'Offen',
            success: 'Erfoglreich',
            skipped: 'Übersprungen',
            failed: 'Fehlgeschlagen',
          },
        },
        createdAt: {
          label: 'Erstellt am',
        },
        createdBy: {
          label: 'Erstellt von',
        },
        startedAt: {
          label: 'Startet am',
        },
        status: {
          label: 'Status',
        },
        action: {
          label: 'Aktionen',
          start: {
            title: 'Adresscheck starten',
            tooltip: 'Bitte klicken Sie hier um den Adresscheck zu starten!',
            content:
              'Wollen Sie den Adresscheck "{{addresscheckName}}" wirklich starten?',
            cancel: 'Abbrechen',
            confirm: 'Starten',
          },
          retry: {
            title: 'Adresscheck erneut senden',
            tooltip:
              'Bitte klicken Sie hier um den Adresscheck erneut zusenden!',
            content:
              'Wollen Sie den Adresscheck "{{addresscheckName}}" wirklich erneut senden?',
            cancel: 'Abbrechen',
            confirm: 'Senden',
          },
        },
        noContent: 'Keine Adresschecks vorhanden',
      },
      search: {
        title: {
          label: 'Adresscheckbezeichnung',
        },
        createdBy: {
          label: 'Erstellt von',
        },
        createdAfter: {
          label: 'Erstellt nach',
        },
        createdBefore: {
          label: 'Erstellt vor',
        },
        description: {
          label: 'Beschreibung',
        },
        startAfter: {
          label: 'Startdatum nach',
        },
        startBefore: {
          label: 'Startdatum vor',
        },
        sent: {
          label: 'Ist Versendet',
        },
        status: {
          label: 'Status',
        },
      },
      confirmDialog: {},
      createAddressCheck: {
        label: 'Adresscheck erstellen',
      },
      rowsPerPage: {
        label: 'Einträge pro Seite',
      },
      startAddressCheck: {
        success: 'Adresscheck: {{title}} wurde erfolgreich gestartet.',
        error: 'Fehler beim Starten des Adresschecks: {{error}}',
      },
      retryAddressCheck: {
        success: 'Adresscheck: {{title}} wurde erfolgreich erneut gesendet.',
        error: 'Fehler beim erneuten Senden des Adresschecks: {{error}}',
      },
    },
  },
  'address-check-public': {
    error: 'Etwas ist schiefgelaufen! Bitte versuchen Sie es später erneut!',
    notifications: {
      copied: 'Text wurde erfolgreich in die Zwischenablage kopiert!',
    },
    login: {
      logo: {
        label: 'Adresscheck',
      },
      prompt: {
        label:
          'Geben Sie die Schulkennzahl und den Berechtigungscode ein, um das Adresscheck-Formular anzuzeigen.',
        error: {
          invalid: 'Dieser Link ist nicht gültig!',
          expired:
            'Verzeihung! Dieser Link ist bereits abgelaufen! Das Ausfüllen eines Adresschecks ist nur 14 Tage nach Erhalt einer Email möglich.',
          alreadyCompleted:
            'Sie haben den Adresscheck abgeschlossen! Vielen Dank!',
        },
      },
      schoolCode: {
        label: 'Schulkennzahl',
      },
      accessCode: {
        label: 'Berechtigungscode',
      },
      start: {
        label: 'Prüfen und Adresscheck starten',
      },
      unauthorized:
        'Die Schulkennzahl und der Berechtigungscode stimmen nicht überein.',
    },
    header: {
      schoolCode: {
        label: 'SKZ',
      },
      benefits: {
        label: 'Ihre Vorteile',
        dialog: {
          label: 'Vorteile des Adresschecks für SCHULEN',
          content:
            '<ul><li>Zusendung von Informationen (z.B. Gratis-Prüfexemplaren) an die korrekte Adresse der Schule</li><li>Durch das Aktualisieren der Liste „Lehrer:innen an Ihrer Schule“ erhält Ihre Schule keine Post-Sendungen mehr zH jener Lehrer:innen, die nicht mehr an Ihrer Schule tätig sind</li><li>Schulen, die am Datencheck teilnehmen, können einen Wellnessgutschein gewinnen</li><li>Beitrag zur Nachhaltigkeit (durch weniger Post-Sendungen wird Papier und CO2 gespart)</li></ul>Übrigens: Lt. DSGVO sind wir als Datenverarbeiter dazu verpflichtet, Daten aktuell zu halten (z.B., indem wir Ihnen den Link zu diesem Datencheck senden).',
          confirm: 'Ok',
        },
      },
    },
    stepper: {
      back: {
        label: 'Zurück',
      },
      next: {
        label: 'Weiter',
        tooltip: 'Scrollen Sie vorher bis zum Ende der Seite',
      },
      complete: {
        label: 'Beenden',
      },
      completeWithFeedback: {
        label: 'Feedback absenden & beenden',
      },
      edit: {
        dialog: {
          label: 'Änderungen speichern',
          content:
            'Sie haben ungespeicherte Änderungen! Bitte bestätigen Sie diese.',
          confirm: 'Ok',
        },
        lotteryDialog: {
          label: 'Mitarbeiter:in auswählen',
          content:
            'Wenn Sie am Gewinnspiel teilnehmen möchten, wählen Sie eine:n Mitarbeiter:in aus.',
          confirm: 'Ok',
        },
      },
      register: {
        open: {
          mobile: {
            label: 'registrieren',
          },
          label: 'BEI VERITAS REGISTRIEREN',
        },
        dialog: {
          label: 'Bei Veritas registrieren?',
          content: {
            label:
              'Möchten Sie sich als neue:r Lehrer:in bei uns registrieren? Nach der Bestätigung Ihrer E-Mail werden Sie auf unsere Website weitergeleitet und können sich dort als neue:r Lehrer:in registrieren. <br/><br/> Bitte aktualisieren Sie die Seite nach abgeschlossener Registrierung, damit auch neu registrierte Mitarbeiter:innen in der Liste angezeigt werden.',
          },
          cancel: {
            label: 'Nein',
          },
          confirm: {
            label: 'Ja',
          },
        },
      },
    },
    steps: {
      schoolData: {
        label: 'Stammdaten',
        edit: {
          start: {
            label: 'Bearbeiten',
          },
          finish: {
            label: 'Änderungen speichern',
            short: {
              label: 'Speichern',
            },
          },
          discard: {
            label: 'Änderungen verwerfen',
            short: {
              label: 'Verwerfen',
            },
          },
        },
        name: {
          label: 'Name',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie den Namen der Schule an.',
          },
        },
        nameAddition: {
          label: 'Name (Zusatz)',
        },
        country: {
          label: 'Land',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie das Land der Schule an.',
          },
        },
        postalCode: {
          label: 'Postleitzahl',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die Postleitzahl der Schule an.',
          },
        },
        city: {
          label: 'Stadt',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die Stadt der Schule an.',
          },
        },
        street: {
          label: 'Straße',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die Straße der Schule an.',
          },
        },
        streetNumber: {
          label: 'Hausnummer',
          error: {
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die Hausnummer der Schule an.',
          },
        },
        streetAddition: {
          label: 'Adresszusatz',
        },
        email: {
          label: 'E-Mail',
          error: {
            pattern:
              'Bitte geben Sie eine valide E-Mailadresse ein. Beispiel: abc@mail.com',
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die E-Mail der Schule an.',
          },
        },
        phone: {
          label: 'Tel.',
          error: {
            pattern:
              'Bitte geben Sie Ihre Telefonnummer in einem unterstützten Format an. Beispiel: +43 12312312312',
            required:
              'Das ist ein Pflichtfeld. Bitte geben Sie die Telefonnummer der Schule an.',
          },
        },
        homepage: {
          label: 'Homepage',
          error: {
            pattern: 'Das ist keine valide Website. Beispiel: www.abc.com',
          },
        },
      },
      teacherList: {
        headmaster: {
          label: 'Direktor:in',
          error: {
            required: 'Bitte wählen Sie eine:n Direktor:in aus!',
            notInTeachers:
              'Der/Die Direktor:in muss der Schule zugewiesen sein!',
          },
        },
        schoolBookManagers: {
          label: 'Schulbuchreferent:innen',
          error: {
            minLength:
              'Bitte wählen Sie mindestens eine:n Schulbuchreferent:in!',
            notInTeachers:
              'Alle Schulbuchreferent:innen müssen der Schule zugewiesen sein!',
          },
        },
        teacherTable: {
          label: 'Lehrer:innenliste',
          info: {
            label: 'Informationen zur Lehrer:innenliste',
            formattedText:
              '<p>Bitte beachten Sie bei der Überprüfung dieser Liste, dass wir Lehrer:innen mit den u.a. Eigenschaften wie angeführt in unserer Datenbank kennzeichnen – bitte entfernen oder belassen Sie das Hakerl entsprechend unserer Beschreibung:</p><ol><li>Lehrer:in in <b>Pension</b>: „An der Schule tätig = nein“ -> <b>bitte <u>ENTFERNEN</u> Sie das Hakerl</b></li><li>Lehrer:in in <b>Karenz</b>: „An der Schule tätig = ja“ -> <b>bitte belassen Sie das Hakerl</b></li><li>Lehrer:in mit <b>Namensänderung</b> (z.B. durch Hochzeit/Scheidung) oder <b>Titeländerung</b>: „An der Schule tätig = ja“ -> <b>bitte belassen Sie das Hakerl</b></li></ol><p>Übrigens: Falls Sie den Lehrer:innen Ihrer Schule die Möglichkeit geben möchten, ihre persönlichen Daten (Name, Titel, etc.) selbst zu aktualisieren bzw. sich bei uns zu registrieren, können Sie das auf der nächsten Seite tun.</p>',
          },
          isTeacher: {
            label: 'An der Schule tätig',
          },
          name: {
            label: 'Name',
          },
          refresh: {
            label: 'Liste aktualisieren',
            tooltip:
              'Hier klicken um die Lehrer:innenliste nach der Registrierung zu aktualisieren.',
          },
          birthdate: {
            label: 'Geburtsdatum',
          },
          email: {
            label: 'E-Mail',
          },
        },
        actions: {
          save: {
            label: 'Änderungen speichern',
            short: {
              label: 'Speichern',
            },
          },
          discard: {
            label: 'Änderungen verwerfen',
            short: {
              label: 'Verwerfen',
            },
          },
        },
      },
      lottery: {
        refresh: {
          label: 'Mitarbeiter:innen aktualisieren',
        },
        teacher: {
          label: 'Wählen Sie eine:n Mitarbeiter:in',
        },
      },
      teacherInvitation: {
        sendEmailButton: {
          label: 'Einladung versenden',
        },
        chooseEmailClient: {
          label: 'Wählen Sie einen E-Mail Client:',
        },
        emailClients: {
          outlook: {
            label: 'Outlook im Browser',
          },
          gmail: {
            label: 'Gmail im Browser',
          },
          default: {
            label: 'Standard E-Mail App',
          },
        },
        manualEmailHint: {
          label: 'Probleme mit dem automatischen Öffnen der E-Mail-Vorlage?',
          info: 'Alternativ können Sie Ihren E-Mail-Client manuell öffnen, unten dargestellte Texte kopieren und an Ihre Kolleg:innen versenden',
        },
        subject: {
          label: 'E-Mail Betreff',
        },
        content: {
          label: 'E-Mail Text',
        },
        copyHover: {
          label: 'Klicken um zu kopieren',
        },
      },
      completion: {
        feedback: {
          placeholder: {
            label: 'Ihr Feedback ist uns wichtig!',
          },
        },
      },
    },
  },
  'address-check-admin': {
    notifications: {
      save: {
        label: 'Die Änderungen wurden erfolgreich gespeichert!',
        error: {
          label: 'Fehler beim Speichern der Änderungen!',
        },
      },
    },
    content: {
      heading: 'Globale Einstellungen',
      settings: {
        timeBetweenAddressChecks: {
          heading: 'Adresschecksperre',
          label: 'Zeit zwischen Adresschecks',
          suffix: 'Monate',
        },
      },
      actions: {
        save: {
          label: 'Änderungen speichern',
        },
        discard: {
          label: 'Änderungen verwerfen',
        },
      },
    },
  },
  'address-check-admin-quick-create': {
    errorMessages: {
      configuration: {
        schoolDataPageMotivatorNotice: {
          pattern: 'Das Gewinnspielenddatum muss in der Zukunft liegen!',
        },
      },
      institution: {
        id: {
          invalid: 'Ungültige Institutions Adressnummer!',
          required: 'Keine Institution Adressnummer ausgewählt!',
        },
        authorizationCode: {
          required:
            'Die ausgewählte Schule hat keinen gültigen Berechtigungscode!',
        },
        schoolCode: {
          required: 'Die ausgewählte Institution hat keine Schulkennzahl!',
        },
        email: {
          required: 'Die ausgewählte Schule hat keine E-Mail-Adresse!',
        },
      },
    },
    content: {
      heading: {
        inProgress: {
          label: 'Adresscheck wird erstellt...',
        },
        done: {
          label: 'Adresscheck versendet!',
        },
      },
      steps: {
        loadConfiguration: {
          label: 'Adresscheckdaten laden',
        },
        createAddressCheck: {
          label: 'Adresscheck erstellen',
        },
        sendAddressCheck: {
          label: 'Adresscheck versenden',
        },
      },
      actions: {
        backToOverview: {
          label: 'Zur Adresscheckübersicht',
        },
      },
    },
  },
};
